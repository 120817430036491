


































































import { Component, Mixins, Watch } from "vue-property-decorator";
import FormCard from "@/components/form/FormCard.vue";
import Contact, { initialOwnerContact } from "@/entity/Contact";
import CompanyFormBusinessInfo from "@/components/company-form/CompanyFormBusinessInfo.vue";
import CompanyFormContactInfo from "@/components/company-form/CompanyFormContactInfo.vue";
import CompanyFormAddressInfo from "@/components/company-form/CompanyFormAddressInfo.vue";
import ContactsService from "@/services/ContactsService";
import DialogMixin from "@/mixins/DialogMixin";
import Project from "@/entity/Project";
import ProjectsService from "@/services/ProjectsService";
import BaseForm from "@/components/form/BaseForm";
import CompanyFormOwnerContact from "@/components/company-form/CompanyFormOwnerContact.vue";
import GlobalService from "@/services/GlobalService";
import { ReferenceItem } from "@/services/types";
import CompanyService from "@/services/CompanyService";
import {
  CreateCompany,
  initialCompanyData,
  contactToCreateCompany,
} from "@/entity/Company";

@Component({
  components: {
    FormCard,
    CompanyFormBusinessInfo,
    CompanyFormContactInfo,
    CompanyFormAddressInfo,
    CompanyFormOwnerContact,
  },
})
export default class CompanyFormCard extends Mixins(DialogMixin, BaseForm) {
  data: CreateCompany = initialCompanyData();
  owners: Contact[] = [];
  projects: Project[] = [];
  companyTypes: ReferenceItem[] = [];
  ownerContact = initialOwnerContact();
  createNewOwnerContact = false;

  async submitForm(): Promise<void> {
    try {
      if (this.isEditing) {
        const response = await CompanyService.updateCompany(
          this.entityId as number,
          this.data
        );
        this.$emit("success", response);
      } else {
        const response = await CompanyService.createCompany({
          ...this.data,
          ownerContact: this.createNewOwnerContact ? this.ownerContact : null,
        });
        this.$emit("success", response);
      }
    } catch (e) {
      if (e.response.status === 409) {
        const confirm = await this.$confirm(
          this.$t("confirmations.contactAlreadyExists").toString()
        );

        if (confirm) {
          await CompanyService.setIsDisabled(e.response.data.message, false);
        }
        await this.$router.push({
          name: "companyDetail",
          params: {
            companyId: e.response.data.message,
          },
        });
      } else if (
        e.response.status === 400 &&
        e.response.data.message === "CONTACT_ALREADY_EXISTS"
      ) {
        this.$snackbarError(this.$tc("apiErrors.companyAlreadyExists"));
        this.$emit("fail");
      } else if (
        e.response.status === 400 &&
        e.response.data.message === "OWNER_CONTACT_ALREADY_EXISTS"
      ) {
        this.$snackbarError(this.$tc("apiErrors.ownerContactAlreadyExists"));
        this.$emit("fail");
      } else {
        this.$snackbarError(this.$tc("apiErrors.unableToSave"));
        this.$emit("fail");
      }
    }
  }

  async loadForm(): Promise<void> {
    if (this.isEditing) {
      const response = await CompanyService.findOne(this.entityId as number);

      this.data = contactToCreateCompany({
        ...response,
      });
    }

    [this.owners, this.projects, this.companyTypes] = await Promise.all([
      ContactsService.findAll(),
      ProjectsService.findAll(),
      GlobalService.getCompanyTypes(),
    ]);
  }

  get address(): string {
    if (!this.data?.address?.address) return "";
    return this.data.address.address;
  }

  get city(): string {
    if (!this.data?.address?.city) return "";
    return this.data.address.city;
  }

  get postcode(): string {
    if (!this.data?.address?.postcode) return "";
    return this.data.address.postcode;
  }

  get country(): number {
    if (!this.data?.address?.country) return 0;
    return this.data.address.country;
  }

  // This is used in ContactFormWrapper
  setData(company: Partial<CreateCompany>): void {
    this.data = {
      ...initialCompanyData(),
      ...company,
    };
  }

  openSearchApiDialog(): void {
    this.$emit("openSearchApiDialog");
  }

  @Watch("data.isExternal")
  onIsExternalChange(): void {
    this.createNewOwnerContact = false;
  }

  @Watch("createNewOwnerContact")
  onCreateNewOwnerContactChange(value: boolean): void {
    if (value) {
      this.data.contactId = null;
    } else {
      this.ownerContact = initialOwnerContact();
    }
  }

  created() {
    this.handleFormLoad(this.loadForm);
  }
}
