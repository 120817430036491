













































import { Component, Mixins, PropSync } from "vue-property-decorator";
import RulesMixin from "@/mixins/RulesMixin";
import { ReferenceItem } from "@/services/types";
import { State } from "vuex-class";

@Component
export default class CompanyFormAddressInfo extends Mixins(RulesMixin) {
  @State("countries", { namespace: "selectOptions" })
  countries!: ReferenceItem[];

  @PropSync("address", { type: String, required: true }) addressLocal!: string;
  @PropSync("postcode", { type: String, required: true })
  postcodeLocal!: string;
  @PropSync("city", { type: String, required: true }) cityLocal!: string;
  @PropSync("countryId", { type: Number }) countryIdLocal!: number | null;
}
